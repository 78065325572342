import React, { useCallback, useRef, useState } from 'react'
import { IVideo, IVideoVariant, Variant, Vector2D } from '../../interfaces'
import { asClassName, isArray, isString, useDebug } from '../../utils'
import { useEventListener } from '../../hooks/use-event'
import styles from './styles.module.scss'

// ─────────────────────────────  Carousel Item  ───────────────────────────────

interface CarouselItemProps extends IVideo {
  active?: boolean
  header?: string
  details?: string[]
  next?: () => void
}

const CarouselItem: React.FC<CarouselItemProps> = (props) => {
  // const debug = useDebug('carousel:item')

  const ref = useRef<HTMLVideoElement>(null)
  const pos = vectorToPosition(props.interest)
  const loop = false
  const tracks = isArray(props.source)
    ? props.source
    : isString(props.source)
      ? [{ src: props.source }]
      : [props.source]

  if (ref.current) {
    /* Forces disableRemotePlayback because both typescript and react seem oblivious */
    if ('disableRemotePlayback' in ref.current) (ref.current.disableRemotePlayback as boolean) = true

    if (props.active) {
      /* Sets the active video time to 0 and plays it */
      ref.current.currentTime = 0
      ref.current.play()
    }
  }

  const next = () => (props.active && props.next ? props.next() : null)
  useEventListener('ended', () => next(), ref)

  return (
    <div className={asClassName(styles.videoContainer, props.active ? null : styles.hidden)}>
      <video
        ref={ref}
        autoPlay
        muted
        playsInline
        x-webkit-airplay='deny'
        loop={loop}
        style={{ objectPosition: pos }}
        poster={props.poster}
        className={styles.video}
      >
        {props.active
          ? tracks.map((source, idx) => {
              return <source key={idx} src={source.src} type={`video/${source.codec || 'mp4'}`} />
            })
          : null}
      </video>
      <div className={asClassName(styles.itemLayer)}>
        <div className={styles.label}>
          {props.header ? <h2> {props.header}</h2> : null}
          {props.details ? props.details.map((detail, idx) => <h3 key={idx}>{detail}</h3>) : null}
        </div>
      </div>
    </div>
  )

  function vectorToPosition(interest?: Vector2D) {
    const pos = interest || {
      x: 0.5,
      y: 0.5,
    }
    return `${pos.x * 100}% ${pos.y * 100}%`
  }
}

// ───────────────────────────────  Carousel  ──────────────────────────────────

interface CarouselProps {
  currency: string
  storeVariants: Variant[]
  variantVideos: IVideo[]
}

export const Carousel: React.FC<CarouselProps> = (props) => {
  const debug = useDebug('carousel:container')

  const [currentIdx, setCurrentIdx] = useState(0)

  const videos = props.variantVideos
    .filter((vid) => vid.enabled)
    .map((vid) => videoToCarouselItemProps(vid, props.storeVariants))
    .filter(notEmpty)

  const advanceCarousel = useCallback(() => {
    const newVal = currentIdx + 1

    if (newVal >= videos.length) setCurrentIdx(0)
    else setCurrentIdx(newVal)
    debug('Current Video idx: %s', currentIdx)
  }, [currentIdx, debug, videos.length])

  return (
    <div className={styles.container} onClick={() => advanceCarousel()}>
      {videos.map((video, idx) => {
        return (
          <CarouselItem key={idx} active={idx === currentIdx} next={() => advanceCarousel()} {...video} />
        )
      })}
    </div>
  )

  function videoToCarouselItemProps(video: IVideo, variants: Variant[]): CarouselItemProps | null {
    if (!video.showDetails) return video
    if (video.variant && video.variant.length) {
      // Only one SKU
      if (video.variant.length === 1) {
        const videoVariant = video.variant[1]
        const variant = variants.find(matchingVariant(videoVariant))
        if (!isAvailable(variant)) return null
        return {
          ...video,
          header: variant.title,
          details: [props.currency.replace('{{amount}}', variant.price)],
        }
      } else {
        // Multiple SKUS
        const videoVariants = video.variant
          .map((videoVariant) => variants.find(matchingVariant(videoVariant)))
          .filter(isAvailable)
          .sort(sortByPrice)
          .map(variantToPrice)

        return {
          ...video,
          details: videoVariants,
        }
      }
    } else {
      return {
        ...video,
        details: variants.filter(isAvailable).sort(sortByPrice).map(variantToPrice),
      }
    }
  }

  function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
    return value !== null && value !== undefined
  }

  function matchingVariant(videoVariant: IVideoVariant) {
    return (variant: Variant) =>
      variant.material === videoVariant.material && variant.size === videoVariant.size
  }

  function sortByPrice(a: Variant, b: Variant) {
    return parseFloat(a.price) - parseFloat(b.price)
  }

  function variantToPrice(variant: Variant) {
    return `${variant.title}: $${variant.price}`
  }
  function isAvailable(variant: unknown): variant is Variant {
    return !!(variant && (variant as Variant).available)
  }
}

// ─────────────────────────────────  Util  ────────────────────────────────────
