// ──────────────────────────────  ENVIRONMENT  ────────────────────────────────
export const ENVIRONMENT = process.env.NODE_ENV || 'development'
export const IS_PROD = ENVIRONMENT === 'production'
export const IS_TEST = ENVIRONMENT === 'test'
export const IS_DEV = !IS_PROD && !IS_TEST
export const EDITABLE = process.env.REACT_APP_EDITABLE === 'true'

export const FIREBASE_COLLECTION = process.env.REACT_APP_FIREBASE_COLLECTION || 'ugc'

export const MOBILE_WIDTH = 768
export const USE_EMULATORS = IS_PROD
  ? false // never use emulators on production
  : process.env.REACT_APP_USE_EMULATORS === 'true'

export const USE_ACCOUNTS = EDITABLE
  ? true // Always use accounts if is editable
  : process.env.REACT_APP_USE_ACCOUNTS === 'true'

export const IS_EMBEDDED = window.self !== window.top

// ──────────────────────────────  Application  ─────────────────────────────────
export const VERSION = process.env.REACT_APP_VERSION || '0.0.0'

export const MIN_NEW_VERSION = '1.0.0'

/**
 * Application State
 * @remarks preview has a value of 99 to allow space for new states
 * States:
 * pristine = 0
 * selectingVideo = 1
 * loadingCreation = 2
 * editingCreation = 3
 * uploadingVideo = 4
 * editingCart = 5
 * previewCreation = 99
 *
 */
export enum AppState {
  erroed = -1,
  pristine = 0,
  selectingVideo = 1,
  loadingCreation = 2,
  editingCreation = 3,
  uploadingVideo = 4,
  editingCart = 5,
  previewCreation = 99,
}

// ───────────────────────────────  Messages  ──────────────────────────────────

export const ALLOWED_ORIGINS = [
  'http://127.0.0.1:9292',
  'https://io-sandbox.myshopify.com',
  'https://infiniteobjects.com',
  'https://infinite-objects-inc.myshopify.com',
  'https://sandbox.infiniteobjects.com',
  'https://io-nft.myshopify.com',
]

export const MESSAGE_TYPES = [
  'ping',
  'pong',
  'app:ready',
  'app:currency',
  'variant:ready',
  'variant:data',
  'variant:selected',
  'creation:id',
  'cart:add',
]
export enum IoMsgType {
  ping = 'ping',
  pong = 'pong',
  ready = 'app:ready',
  currency = 'app:currency',
  variantReady = 'variant:ready',
  variantData = 'variant:data',
  variantSelected = 'variant:selected',
  setCreationId = 'creation:id',
  addToCart = 'cart:add',
}

// ───────────────────────────────  Creation  ──────────────────────────────────

export enum CreationState {
  erroed = -1,
  pristine = 0,
  localVideo = 1,
  hasUploaded = 2,
  hasDerivedVideos = 3,
}
export const MAX_UPLOAD_SIZE = 1000 * 1000 * 1000

// ────────────────────────────────  Devices  ──────────────────────────────────

export const TEXTURE_SIZE = 2500
export const MAX_TEXTURE_SIZE = TEXTURE_SIZE * 1.5

export const IO_WIDTH_5 = 854
export const IO_HEIGHT_5 = 480
export const IO_WIDTH_7 = 1024
export const IO_HEIGHT_7 = 576
export const IO_WIDTH_10 = 1024
export const IO_HEIGHT_10 = 576

export enum IoSize {
  sevenInch = 'IO7',
  fiveInch = 'IO5',
  tenInch = 'IO10',
}

export enum IoOrientation {
  portrait = 'PORTRAIT',
  landscape = 'LANDSCAPE',
}

export enum IoMaterial {
  acrylic = 'ACRYLIC',
  bamboo = 'BAMBOO',
}

export const SHOW_SHIPPING_TIME = true

// ──────────────────────────────  Transforms  ─────────────────────────────────

export enum Loop {
  normal = 'NORMAL',
  boomerang = 'BOOMERANG',
  fade = 'FADE_IN_OUT',
}
export enum Filter {
  none = 'NONE',
  blackWhite = 'BLACK_WHITE',
  sepia = 'SEPIA',
  vivid = 'VIVID',
}

// ───────────────────────────────  Interface  ─────────────────────────────────
export enum CSSFilter {
  none = 'none',
  blackWhite = 'grayscale(100%)',
  sepia = 'sepia(100%)',
  vivid = 'saturate(200%)',
}

/** Used on mobile ui */
export enum Tabs {
  size,
  filter,
  edit,
}

/** Used on mobile ui */
export enum EditTabs {
  speed,
  trim,
  zoom,
  loop,
  rotate,
}
